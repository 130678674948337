/** Dashboard Layout Header
 *  Goes inside of dashboard body above main
 */
import { useContext } from 'react'
import React from 'react'
import type { ILayoutComponentProps } from '@unionco/alaris-app-types'

import { PreAuthGlobalsContext } from 'context'

export const DashboardLayoutHeader: React.FC<ILayoutComponentProps> = ({
  children,
  useNewStyling = false
}) => {
  const { loginBackground } = useContext(PreAuthGlobalsContext)
  return (
    <div className='c-withLayer | u-flex u-basis-full u-justify-center'>
      {!useNewStyling && (
        <div className='c-layer | u-h-[170%] u-bg-gradient-blue-black'>
          <div
            className='u-h-full u-w-full'
            style={{
              backgroundImage: `url(${loginBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100%',
              backgroundPosition: 'center'
            }}
          />
        </div>
      )}
      {children}
    </div>
  )
}

export default DashboardLayoutHeader
