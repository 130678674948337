import React from 'react'
import { ITopPrioritiesData } from '@unionco/alaris-app-types'
import { ModuleCard } from 'components'
import { PriorityList } from './PriorityList'
import tw from 'twin.macro'


interface ITopPrioritiesProps {
  data: ITopPrioritiesData
  title?: string
  className?: string
  titleClassName?: string
  bgClassName?: string
  style?: React.CSSProperties;
}

export const TopPriorities: React.FC<ITopPrioritiesProps> = ({
  bgClassName,
  className,
  data: { info, items },
  title,
  titleClassName,
}) => {
  if (!items || items.length === 0) return <></>
  title = title || 'Your Top Priorities'
  return (
    <div>
      <h5 className="u-self-start u-pb-6">{title}</h5>
      <ModuleCard.Body
        className='print:u-break-inside-avoid-page print:u-py-0'
        useNewStyling={true}
        bgClassName={bgClassName}
      >
        <div className={`${className}`}>
          <PriorityList items={items} />
        </div>
      </ModuleCard.Body>
    </div>
  )
}

export default TopPriorities
