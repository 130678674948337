/** File Icon
 *
 */
import { TSVGProps } from '@unionco/alaris-app-types'

import { TwStyle } from 'twin.macro'

interface IFileIconProps extends TSVGProps {
    className?: string
}

export const FileIcon: React.FC<IFileIconProps> = ({ ...props }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M3 1H15.414L21 6.586V23H3V1ZM5 3V21H19V9H13V3H5ZM15 3.414V7H18.586L15 3.414Z" fill="#2C76F0" />
        </svg>
    )
}

export default FileIcon
