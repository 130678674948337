/** Dashboard Header
 *  Navigation (header) component, specific
 *  to the dashboard template.
 *
 *  Displays company name, Alaris logo, help button, and account dropdown component.
 */
import { useBreakAtSize } from '@unionco/components'

import { TPageName } from '@appConstants'

import { AccountDropdown, NotificationMenu, IconHeader, Logo, SupportLink } from 'components'

export interface IDashboardHeaderProps {
  eyebrow: string
  headerText: string
  pageName: TPageName
  useNewStyling?: boolean
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
  eyebrow,
  headerText,
  pageName,
  useNewStyling = false
}) => {
  const { aboveBreakPoint: tabletLayout } = useBreakAtSize('sm')
  const { aboveBreakPoint: desktopLayout } = useBreakAtSize('md')

  return (
    <>
      {/* If useNewStyling is true, render the new design layout */}
      {useNewStyling ? (
        <nav className='c-wrapper | u-flex u-items-center u-justify-between u-py-500 u-text-black'>
          <div className='u-flex u-items-center u-justify-between u-w-full'>
            <div className='u-flex-grow'></div>
            <div className='u-flex-grow u-flex u-justify-center'>
              <Logo width='133' height='23' />
            </div>
            <div className='u-flex u-items-center u-ml-auto u-space-x-12'>
              <AccountDropdown mobileLayout={!tabletLayout} pageName={pageName} useNewStyling={true} />
              <NotificationMenu useNewStyling={true} />
            </div>
          </div>
        </nav>
      ) : ( // Otherwise, render the old design layout
        <nav className='c-wrapper | u-flex u-items-center u-justify-between u-py-700 u-text-white'>
          <IconHeader
            icon='dashboard'
            eyebrow={eyebrow}
            header={headerText}
            dark
            isLink={false}
          />
          {desktopLayout && <Logo />}
          <div className='c-cluster'>
            {tabletLayout && <SupportLink theme='light' />}
            <NotificationMenu />
            <AccountDropdown mobileLayout={!tabletLayout} pageName={pageName} />
          </div>
        </nav>
      )}
    </>
  )
}

export default DashboardHeader
