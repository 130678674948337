/** General Information Test Data
 *  Test data for the <CompanyDetails /> module
 *  used in the profile template
 *  A shared side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { ICompanyDetailsData } from '@unionco/alaris-app-types'

import {
  aumTotalTestData,
  businessLocationTestData,
  custodiansUsedTestData,
  feeModelTestData,
  threeYearAvgNNAGrowthTestData,
  totalStaffTestData
} from '@fixtures/business'
import { shortParagraphFixture } from '@fixtures/generic'

const blTD = businessLocationTestData()
const atTD = aumTotalTestData()
const fmTD = feeModelTestData()
const cuTD = custodiansUsedTestData()
const tsTD = totalStaffTestData()
const tyang = threeYearAvgNNAGrowthTestData()

export const companyDetailsModuleTestData = {
  moduleType: 'CompanyDetails',
  title: 'Company Details',
  items: [blTD, atTD, fmTD, cuTD, tsTD, tyang],
  key: uuidv4(),
  container: 2,
  info: {
    copy: shortParagraphFixture
  }
} as ICompanyDetailsData
