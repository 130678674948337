/** Buyer At A Glance Test API
 *
 */
import { IProfileTemplateData } from 'templates'

import {
  acquisitionModelTestData,
  averageGrowthStatisticsTestData,
  clientExpereienceModuleTestData,
  dataRoomModuleTestData,
  companyDetailsModuleTestData,
  historicGrowthStatisticsTestData,
  organicGrowthModuleTestData,
  pointsOfAlignmentTestData,
  servicesOfferedModuleTestData,
  teamMemberModuleTestData
} from '@fixtures/modules'

export const buyerAtAGlanceFixture = {
  companyName: 'Business Name',
  modules: [
    teamMemberModuleTestData,
    companyDetailsModuleTestData,
    clientExpereienceModuleTestData,
    servicesOfferedModuleTestData,
    acquisitionModelTestData,
    pointsOfAlignmentTestData,
    organicGrowthModuleTestData,
    averageGrowthStatisticsTestData,
    historicGrowthStatisticsTestData,
    dataRoomModuleTestData
  ]
} as IProfileTemplateData
