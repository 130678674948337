import React from 'react';
import { Img } from 'components/generic';
import { LocationIcon, CopyIcon } from 'components';

interface DashboardCompanyDetailsProps {
    companyLogo: string;
    companyName: string;
    companyLocation: string;
    companyWebsite: string;
}

export const DashboardCompanyDetails: React.FC<DashboardCompanyDetailsProps> = ({ companyLogo, companyName, companyLocation, companyWebsite }) => {
    return (
        <div className='u-flex u-items-center'>
            <div className='u-pe-6'>
                <div className='u-flex u-items-center u-justify-center u-w-24 u-h-24 u-px-3 u-py-7 u-rounded-full u-bg-white u-shadow-sm'>
                    {companyLogo && (
                        <Img src={companyLogo} alt='' width={72} height={36} />
                    )}
                </div>
            </div>
            <div>
                <h4 className='u-pb-3'>{companyName}</h4>
                <div className='u-flex u-space-x-2'>
                    {companyLocation && (
                        <>
                            <LocationIcon className='u-w-6' />
                            <p>{companyLocation}</p>
                        </>
                    )}
                    {companyWebsite && (
                        <>
                            <p className='u-ps-4'>Website: {companyWebsite}</p>
                            <CopyIcon
                                className='u-w-6 u-cursor-pointer'
                                onClick={() => navigator.clipboard.writeText(companyWebsite)}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardCompanyDetails;