import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const CopyIcon: React.FC<TSVGProps> = ({ ...props }) => {
    return (
        <IconSVG
            viewBoxWidth={24}
            viewBoxHeight={25}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
            ref={undefined}
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.52344 1.7627V18.1837H7.57607V23.2364H20.2076V6.81533H15.155V1.7627H2.52344ZM17.6813 9.34164H15.155V18.1837H10.1024V20.7101H17.6813V9.34164ZM5.04975 4.28901H12.6287V6.81533V9.34164V15.6574H10.1024H7.57607H5.04975V4.28901Z"
                fill="#647381"
            />
        </IconSVG>
    )
}

export default CopyIcon
