import React from 'react'

import { IContent } from '@unionco/alaris-app-types'

import { numberCountDisplayText } from 'utils'

interface IPriorityListProps {
  items: IContent[]
}

export const PriorityList: React.FC<IPriorityListProps> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => {
        const { content } = item
        const displayNumber = index + 1
        return (
          <React.Fragment key={`SellerTopPriorityItem_${index}`}>
            {index !== 0 && <div className='o-line u-my-2 u-m-auto' />}
            <div className='u-flex u-items-start'>
              <div className='u-w-6 u-flex-shrink-0'>
                <h5 className='u-text-paragraph-sm u-text-newprimary-700'>
                  {numberCountDisplayText(displayNumber)}
                </h5>
              </div>
              <p className='u-ml-2 u-text-left u-leading-6 u-text-paragraph-sm u-text-secondary-800'>{content}</p>
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}
