/** Titled List
 *  Basic Output: Title stacked on top of item
 *  <>
 *   { Title }
 *   { item }
 *  </>
 *
 *  Used in both General Info and Risk Factors Mod
 *  The "item" has a couple of different types,
 *  but eventually lands on a string
 */
import { Tooltip } from '@unionco/components'
import tw from 'twin.macro'

import type {
  ITitleAndCopyData,
  ITitledListItemData,
  TTitledListCopyData
} from '@unionco/alaris-app-types'

import { booleanToText, cx } from '@unionco/utils'

import { TitledListItem } from 'components'

interface ITitleListItemProps {
  className: string
  item: TTitledListCopyData
}

interface ITitleListProps {
  className?: string
  composition?: 'c-dep-repel' | 'c-cluster'
  infoRevealCallback?: () => void
  items: ITitledListItemData[]
}

const TitleListItem: React.FC<ITitleListItemProps> = ({ className, item }) => {
  const itemType = typeof item
  const itemIsArray = Array.isArray(item)



  if (itemType === 'string' || itemType === 'number') {
    // Regular expression to check if item is a URL
    const urlPattern = new RegExp('^(http|https)://', 'i');

    const isUrl = urlPattern.test(item as string);

    // if item is a URL, return an anchor tag
    return isUrl ? (
      <a href={item as string} target="_blank" rel="noopener noreferrer" className={cx(className, 'o-body-1 text-sm u-text-wrap')}>
        {item as string | number}
      </a>
    ) : (
      <p className={cx(className, 'text-sm u-text-wrap')}>{item as string | number}</p>
    );
  } else if (itemIsArray) {
    return (
      <>
        {item.map((i, index) => {
          if (typeof i === 'string') {
            return (
              <p
                className={cx(className, 'u-text-wrap')}
                key={`StringListItem_${index}`}
              >
                {i as string | number}
              </p>
            )
          } else {
            return (
              <TitledListItem
                className={className}
                {...i}
                key={`TitledListItem_${index}`}
              />
            )
          }
        })}
      </>
    )
  } else if (itemType === 'object') {
    return (
      <TitledListItem className={className} {...(item as ITitleAndCopyData)} />
    )
  }

  return <></>
}

export const TitledList: React.FC<ITitleListProps> = ({
  className,
  composition = 'c-dep-repel',
  infoRevealCallback = () => {
    return undefined
  },
  items
}) => {
  return (
    <div className='print:u-flex print:u-flex-row print:u-flex-wrap'>
      {items.map((i, index) => {
        const { item, title } = i
        const value =
          typeof item !== 'boolean' ? item : booleanToText(item as boolean)

        return (
          <div
            key={`TitledListItem_${index}`}
            className={cx(`print:u-flex print:u-w-1/3 print:u-p-2 print:u-text-left`, className)}
          >
            <div className={cx('b-TitledList', composition)}>
              <h5 className='u-text-secondary-800 u-text-paragraph-sm'>{title}</h5>
              {!!i.info && (
                <Tooltip
                  iconColorClass={tw`u-text-primary-500`}
                  {...i.info}
                  revealCallback={infoRevealCallback}
                />
              )}
            </div>
            <TitleListItem className='u-text-paragraph-sm u-leading-6 u-mb-2' item={value} />
          </div>
        )
      })}
    </div>
  )
}

export default TitledList
