import type { IPointsOfAlignmentData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy } from 'components'

import BulletIcon from 'components/svg/icons/bullet/Bullet'

interface IPointsOfAlignmentProps {
  data: IPointsOfAlignmentData
}

export const PointsOfAlignment: React.FC<IPointsOfAlignmentProps> = ({
  data: { copy, info, items, title }
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <div>
      <h5 className="u-self-start u-pb-6">Points of Alignment</h5>
      <div className="u-flex u-flex-col u-gap-2">
        {items.map((item, index) => (
          <div
            key={index}
            className="u-flex-1 p-2 box-border u-text-left"
          >
            <ModuleCard.Body useNewStyling={true} innerCardPadding={['u-px-8', 'u-py-6']}>
              <ModuleCard.InfoHeader info={info} trackingName='points of alignment module' />
              <p className='lens-overline-text u-mb-2'>{item.title}</p>
              <div className='u-flex u-items-start'>
                <BulletIcon />
                <p className='u-ml-4'>{item.value}</p>
              </div>
            </ModuleCard.Body>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PointsOfAlignment
