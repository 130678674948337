import { ModuleCard } from 'components/layout'

export const MatchesCard: React.FC<any> = ({
  data: { items },
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <div>
      <h5>Top Matches</h5>
      <ModuleCard.Body type='top-matches' matchesContent={items.buyers}>
      </ModuleCard.Body>
    </div>
  )
}

export default MatchesCard
