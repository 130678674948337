import { Accordion } from '@unionco/components'
import ChevronIcon from 'components/svg/icons/chevron'
import React from 'react'

import { IDataRoomData } from '@unionco/alaris-app-types'

import { Card } from '@unionco/components'
import { cx } from '@unionco/utils'
import tw from 'twin.macro'

import DownloadListItem from './DownloadListItem'

interface IDataRoomProps {
  data: IDataRoomData
}

export const DataRoom: React.FC<IDataRoomProps> = ({
  data: {
    info,
    items: { items } = {},
    title,
  }
}) => {
  if (!items || items.length === 0) return <>
    <h5>No files to display.</h5>
  </>

  const numOfCategories = items.length

  return !!items && numOfCategories !== 0 ? (
    <>
      <div>
        <h5 className="u-self-start u-pb-6">
          Data Room
        </h5>
        <div className="u-flex u-flex-col u-gap-2">
          {items.map((section, index) => {
            const { items, title } = section;
            const padding = index === numOfCategories - 1 ? 'u-pt-5' : 'u-py-5';

            const workingLinks =
              items === null
                ? []
                : items.filter((i) => {
                  if (!i.href) return false;
                  return i.href !== '#';
                });

            if (workingLinks.length === 0) return null;

            return (
              <div>
                <Card
                  className={cx('u-flex u-flex-col u-p-2')}
                  bgColorClass={tw`u-bg-opacity-40`}
                  cssClasses={[tw`u-rounded-[32px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`]}
                >
                  <Card
                    className={cx('c-flow')}
                    bgColorClass={tw`u-bg-opacity-60`}
                    cssClasses={[tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`]}
                  >
                    <Accordion.Main className={`c-accordion u-bg-gradient-card u-rounded-[24px] u-overflow-hidden`}
                      css={[tw`u-rounded-[24px] u-border-none u-backdrop-blur-[100px]`]}>
                      <Accordion.Item index={index} key={`DataRoomList_${index}`} className={'u-px-8 u-py-4'}>
                        <Accordion.Button
                          activeClassName='!u-text-black'
                          className={`c-accordion-button u-border-none u-text-secondary-800 u-bg-gradient-card u-p-0`}
                          index={index}
                          locked={false}
                        >
                          {<p className="lens-overline-text u-pr-4">{title}</p>}
                          <Accordion.Indicator index={index} animation='downToUp'>
                            <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                              <ChevronIcon />
                            </div>
                          </Accordion.Indicator>
                        </Accordion.Button>
                        <Accordion.Drawer index={index}>
                          {workingLinks.map((item, itemIndex) => (
                            <div className='u-pt-2'>
                              <Card
                                key={`DataRoomListItem_${itemIndex}`}
                                bgColorClass={tw`u-bg-opacity-60`}
                                cssClasses={[tw`u-rounded-[24px] u-border u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`]}
                              >
                                <DownloadListItem useNewStyling={true} {...item} />
                              </Card>
                            </div>
                          ))}
                        </Accordion.Drawer>
                      </Accordion.Item>
                    </Accordion.Main>
                  </Card>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
}

export default DataRoom
