/** Acquistion Model Module
 *  Buyer Profile Sidebar Module
 *  Displays list of information on how buyers
 *  handle acquisition process. Each title of
 *  a given list item has it's own info component,
 *  where as the module itself does not.
 *
 *  Design shows:
 *  Acquisition Type, Requires Brand Adoption, Max Cash,
 *  Max Equity, Has Earn-out, Permits BD Affiliation,
 *  Permits IM Autonomy, Permits 1099's, Requires CX adoption,
 *  Share of ongoing economics.
 *
 *  Each with it's own "answer" below the title
 */
import type { IAcquisitionModelData } from '@unionco/alaris-app-types'
import React from 'react'

import { capitalizeAndSplit } from '@unionco/utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard } from 'components'

import BulletIcon from 'components/svg/icons/bullet/Bullet'


interface IAcquisitionModelProps {
  className?: string
  data: IAcquisitionModelData
}

export const AcquisitionModel: React.FC<IAcquisitionModelProps> = ({
  className,
  data: {
    acquisitionTypeTooltip,
    items,
    sharesOngoingEconomicsTooltip,
    maxCashTooltip,
    maxEquityTooltip,
    requiresBrandAdoptionTooltip,
    retentionTermsTooltip,
    title
  }
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()

  const getDisplayName = (key: string) => {
    return capitalizeAndSplit(key)
  }

  const getTooltip = (key: string) => {
    const cappedKey = `${key.charAt(0).toUpperCase()}${key.slice(1)}`
    switch (cappedKey) {
      case 'AcquisitionType':
        return acquisitionTypeTooltip
      case 'MaxCash':
        return maxCashTooltip
      case 'MaxEquity':
        return maxEquityTooltip
      case 'RequiresBrandAdoption':
        return requiresBrandAdoptionTooltip
      case 'RetentionTerms':
        return retentionTermsTooltip
      case 'SharesOngoingEconomics':
        return sharesOngoingEconomicsTooltip
      default:
        return null
    }
  }

  const liItems = items.map((item) => {
    const { title } = item
    return {
      ...item,
      info: getTooltip(title),
      title: getDisplayName(title)
    }
  })

  //remove "Requires Brand Adoption" from list
  const filteredListItems = liItems.filter(item => item.title !== "Requires Brand Adoption")

  filteredListItems.map((item) => {
    const { title } = item
    return {
      ...item,
      info: getTooltip(title),
      title: getDisplayName(title)
    }
  })

  // alphabetize liItems
  filteredListItems.sort((a, b) => {
    const aTitle = a.title.toLowerCase()
    const bTitle = b.title.toLowerCase()
    if (aTitle < bTitle) return -1
    if (aTitle > bTitle) return 1
    return 0
  })


  if (!filteredListItems || filteredListItems.length === 0) return <></>

  return (
    <div>
      <h5 className="u-self-start u-pb-6">
        Acquisition Model
      </h5>
      <div className="u-flex u-flex-col u-gap-2 u-text-left">
        <ModuleCard.Body useNewStyling={true} innerCardPadding={['u-px-8', 'u-py-8']}>
          {filteredListItems.map((item, index) => (
            <React.Fragment key={index}>
              <div className='u-flex u-flex-col u-gap-2 u-mb-6'>
                <p className='lens-overline-text'>{item.title}</p>
                <div className='u-flex u-flex-col u-gap-4'>
                  {typeof item.item === 'string' && (
                    <div className='u-flex u-items-start'>
                      <BulletIcon />
                      <p className='u-ml-4'>{item.item}</p>
                    </div>
                  )}
                  {Array.isArray(item.item) && item.item.map((subItem, subIndex) => (
                    typeof subItem === 'string' && (
                      <div key={subIndex} className='u-flex u-items-start'>
                        <BulletIcon />
                        <p className='u-ml-4'>{subItem}</p>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </React.Fragment>
          ))}
        </ModuleCard.Body>
      </div>
    </div>
  )
}

export default AcquisitionModel
