import { ChartOptions } from 'chart.js'
import { Bar } from 'react-chartjs-2'

import type { IThemeObj, TBarChartData } from '@unionco/alaris-app-types'

import { themeColors } from 'styles'

interface IBarChartProps {
  barColors?: string[]
  data: TBarChartData
  options: ChartOptions<'bar'>
}

export const BarChart: React.FC<IBarChartProps> = ({
  barColors,
  data,
  options
}) => {
  const styledOptions = {
    elements: {
      bar: {
        backgroundColor: (themeColors as IThemeObj).primary['500'],
        borderRadius: 8
      }
    },
    ...options
  }

  const styledData =
    !barColors || barColors.length === 0
      ? data
      : {
        ...data,
        datasets: data.datasets.map((set) => {
          return {
            ...set,
            backgroundColor: barColors
          }
        })
      }

  return (
    <Bar
      className='u-max-w-[100%] u-bg-opacity-40'
      data={styledData}
      options={styledOptions}
    />
  )
}

export default BarChart
