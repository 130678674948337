/** Modules
 *  A dynamic switch component that will only call the .js needed for a particular
 *  profile page
 *
 *  Non-next example of React based code splitting using lazy and suspense
 *  Which looked interesting because it allowed for variable useage in the
 *  dynamic import. Still utilizes React.lazy and <Suspense> so it may be an
 *  option
 *  @see https://www.digitalocean.com/community/conceptual-articles/react-loading-components-dynamically-hooks#loading-components-dynamically
 */
import dynamic from 'next/dynamic'

import type {
  IAcquisitionModelData,
  IAdvisorValuePropositionModuleData,
  IAutonomyProfileModuleData,
  IAverageGrowthStatisticsData,
  IClientDemographicsData,
  IClientExperienceModuleData,
  IClientProfileModuleData,
  ICompanyDetailsData,
  IContactLinkData,
  IDataRoomData,
  IFinancialSnapshotData,
  IHistoricGrowthData,
  IHistoricGrowthStatisticsData,
  IInvestmentManagementData, // IInfoData,
  IKeyRatiosAndMetricsData,
  IMatchesData,
  IOrganicGrowthData,
  IPointsOfAlignmentData,
  IRiskFactorsData,
  ISeekingPartnershipModuleData,
  IServicesOfferedData,
  ITeamMembersModuleData,
  ITitledVideoData,
  ITopPrioritiesData
} from '@unionco/alaris-app-types'

import { Loading } from 'components'

// #region - dynamic modules
export const AlarisRepContactDynamicMod = dynamic(
  () => import('components/ContactLink'),
  {
    loading: () => <Loading debug='AlarisRepContactDynamicMod' />
  }
)

export const AcquisitionModelDynamicMod = dynamic(
  () => import('components/modules/AcquisitionModel'),
  {
    loading: () => <Loading debug='AcquisitionModelDynamicMod' />
  }
)

export const AverageGrowthStatisticsDynamicMod = dynamic(
  () => import('components/modules/AverageGrowthStatistics'),
  {
    loading: () => <Loading debug='AverageGrowthStatisticsDynamicMod' />
  }
)

export const ClientDemographicsDynamicMod = dynamic(
  () => import('components/modules/ClientDemographics'),
  {
    loading: () => <Loading debug='ClientDemographicsDynamicMod' />
  }
)

export const ClientProfilesDynamicMod = dynamic(
  () => import('components/modules/ClientProfiles'),
  {
    loading: () => <Loading debug='ClientProfilesDynamicMod' />
  }
)

export const ClientExperiencesDynamicMod = dynamic(
  () => import('components/modules/ClientExperiences'),
  {
    loading: () => <Loading debug='ClientExperiencesDynamicMod' />
  }
)

export const SeekingPartnershipDynamicMod = dynamic(
  () => import('components/modules/SeekingPartnership'),
  {
    loading: () => <Loading debug='SeekingPartnershipDynamicMod' />
  }
)

export const AdvisorValuePropositionDynamicMod = dynamic(
  () => import('components/modules/AdvisorValueProposition'),
  {
    loading: () => <Loading debug='AdvisorValuePropositionDynamicMod' />
  }
)

export const DataRoomDynamicMod = dynamic(
  () => import('components/modules/DataRoom'),
  {
    loading: () => <Loading debug='DataRoomDynamicMod' />
  }
)

export const FinancialSnapshotDynamicMod = dynamic(
  () => import('components/modules/FinancialSnapshot'),
  {
    loading: () => <Loading debug='FinancialSnapshotDynamicMod' />
  }
)

export const CompanyDetailsDynamicMod = dynamic(
  () => import('components/modules/CompanyDetails'),
  {
    loading: () => <Loading debug='CompanyDetailsDynamicMod' />
  }
)

export const HistoricGrowthStatisticsDynamicMod = dynamic(
  () => import('components/modules/HistoricGrowthStatistics'),
  {
    loading: () => <Loading debug='HistoricGrowthStatisticsDynamicMod' />
  }
)

export const KeyRatiosAndMetricsDynamicMod = dynamic(
  () => import('components/modules/KeyRatiosAndMetrics'),
  {
    loading: () => <Loading debug='KeyRatiosAndMetricsDynamicMod' />
  }
)

export const HistoricGrowthDataDynamicMod = dynamic(
  () => import('components/modules/HistoricGrowthData'),
  {
    loading: () => <Loading debug='HistoricGrowthDataDynamicMod' />
  }
)

export const OrganicGrowthDynamicMod = dynamic(
  () => import('components/modules/OrganicGrowth'),
  {
    loading: () => <Loading debug='OrganicGrowthDynamicMod' />
  }
)

export const PointsOfAlignmentDynamicMod = dynamic(
  () => import('components/modules/PointsOfAlignment'),
  {
    loading: () => <Loading debug='PointsOfAlignmentDynamicMod' />
  }
)

export const RiskFactorsDynamicMod = dynamic(
  () => import('components/modules/RiskFactors'),
  {
    loading: () => <Loading debug='RiskFactorsDynamicMod' />
  }
)

export const TopPrioritiesDynamicMod = dynamic(
  () => import('components/modules/TopPriorities'),
  {
    loading: () => <Loading debug='TopPrioritiesDynamicMod' />
  }
)

export const TopMatchesDynamicMod = dynamic(
  () => import('components/modules/MatchesCard'),
  {
    loading: () => <Loading debug='TopMatchesDynamicMod' />
  }
)

export const ServicesOfferedDynamicMod = dynamic(
  () => import('components/modules/ServicesOffered'),
  {
    loading: () => <Loading debug='ServicesOfferedDynamicMod' />
  }
)

export const TeamMembersDynamicMod = dynamic(
  () => import('components/modules/TeamMembers'),
  {
    loading: () => <Loading debug='TeamMembersDynamicMod' />
  }
)

export const TitledVideoDynamicMod = dynamic(
  () => import('components/modules/TitledVideo'),
  {
    loading: () => <Loading debug='TitledVideoDynamicMod' />
  }
)

export const InvestmentManagementDynamicMod = dynamic(
  () => import('components/modules/InvestmentManagement'),
  {
    loading: () => <Loading debug='InvestmentManagementMod' />
  }
)

export const AutonomyProfileDynamicMod = dynamic(
  () => import('components/modules/AutonomyProfile'),
  {
    loading: () => <Loading debug='AutonomyProfile' />
  }
)
// #endregion - dynamic modules

// All Modules listed below extend the IModuleData interface
export interface IBaseModuleData {
  tab?: string;
  position?: 'Main' | 'Right Side';
}

export type TModuleData =
  | (IAcquisitionModelData & IBaseModuleData)
  | (IContactLinkData & IBaseModuleData)
  | (IAverageGrowthStatisticsData & IBaseModuleData)
  | (IClientDemographicsData & IBaseModuleData)
  | (IClientProfileModuleData & IBaseModuleData)
  | (IClientExperienceModuleData & IBaseModuleData)
  | (ISeekingPartnershipModuleData & IBaseModuleData)
  | (IAdvisorValuePropositionModuleData & IBaseModuleData)
  | (IDataRoomData & IBaseModuleData)
  | (IFinancialSnapshotData & IBaseModuleData)
  | (ICompanyDetailsData & IBaseModuleData)
  | (IHistoricGrowthData & IBaseModuleData)
  | (IHistoricGrowthStatisticsData & IBaseModuleData)
  | (IKeyRatiosAndMetricsData & IBaseModuleData)
  | (IOrganicGrowthData & IBaseModuleData)
  | (IPointsOfAlignmentData & IBaseModuleData)
  | (IRiskFactorsData & IBaseModuleData)
  | (ITopPrioritiesData & IBaseModuleData)
  | (IServicesOfferedData & IBaseModuleData)
  | (ITeamMembersModuleData & IBaseModuleData)
  | (IMatchesData & IBaseModuleData)
  | (ITitledVideoData & IBaseModuleData)
  | (IInvestmentManagementData & IBaseModuleData)
  | (IAutonomyProfileModuleData & IBaseModuleData);


interface IModulesProps {
  className?: string
  data: TModuleData
}

export const Modules: React.FC<IModulesProps> = ({ data, ...props }) => {
  const { moduleType } = data
  switch (moduleType) {
    case 'AlarisRepContact':
      return <AlarisRepContactDynamicMod data={data} {...props} />
    case 'AcquisitionModel':
      return <AcquisitionModelDynamicMod data={data} {...props} />
    case 'AverageGrowthStatistics':
      return <AverageGrowthStatisticsDynamicMod data={data} {...props} />
    case 'ClientDemographics':
      return <ClientDemographicsDynamicMod data={data} {...props} />
    case 'ClientProfiles':
      return <ClientProfilesDynamicMod data={data} {...props} />
    case 'ClientExperiences':
      return <ClientExperiencesDynamicMod data={data} {...props} />
    case 'SeekingPartnership':
      return <SeekingPartnershipDynamicMod data={data} {...props} />
    case 'AdvisorValueProposition':
      return <AdvisorValuePropositionDynamicMod data={data} {...props} />
    case 'DataRoom':
      return <DataRoomDynamicMod data={data} {...props} />
    case 'FinancialSnapshot':
      return <FinancialSnapshotDynamicMod data={data} {...props} />
    case 'CompanyDetails':
      return <CompanyDetailsDynamicMod data={data} {...props} />
    case 'HistoricGrowthData':
      return <HistoricGrowthDataDynamicMod data={data} {...props} />
    case 'HistoricGrowthStatistics':
      return <HistoricGrowthStatisticsDynamicMod data={data} {...props} />
    case 'KeyRatiosAndMetrics':
      return <KeyRatiosAndMetricsDynamicMod data={data} {...props} />
    case 'OrganicGrowth':
      return <OrganicGrowthDynamicMod data={data} {...props} />
    case 'PointsOfAlignment':
      return <PointsOfAlignmentDynamicMod data={data} {...props} />
    case 'RiskFactors':
      return <RiskFactorsDynamicMod data={data} {...props} />
    case 'TopPriorities':
      return <TopPrioritiesDynamicMod data={data} {...props} />
    case 'TopMatches':
      return <TopMatchesDynamicMod data={data} {...props} />
    case 'ServicesOffered':
      return <ServicesOfferedDynamicMod data={data} {...props} />
    case 'TeamMembers':
      return <TeamMembersDynamicMod data={data} {...props} />
    case 'TitledVideo':
      return <TitledVideoDynamicMod data={data} {...props} />
    case 'InvestmentManagement':
      return <InvestmentManagementDynamicMod data={data} {...props} />
    case 'AutonomyProfile':
      return <AutonomyProfileDynamicMod data={data} {...props} />
    default:
      return <></>
  }
}

export default Modules
