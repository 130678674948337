import { ICompanyDetailsData } from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard, TitledList, Video } from 'components'

interface ICompanyDetailsProps {
  data: ICompanyDetailsData
}

export const CompanyDetails: React.FC<ICompanyDetailsProps> = ({
  data: { info, items, title, video, companyType }
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  const trackingName = 'general information module'
  title = title || 'Company Details'

  const itemsPresent = items && items.length > 0
  if (!itemsPresent && !video) return <></>
  const getDisplayName = (key: string) => {
    switch (key) {
      case 'aumTotal':
      case 'AumTotal':
        return 'Approximate AUM'
      case 'TotalStaff':
        return 'Number of Employees'
      case 'CapitalSource':
        return 'Capital Source'
      default:
        return capitalizeAndSplit(key)
    }
  }
  const liItems = items.map((item) => {
    if (item.title === 'CapitalSource' && Array.isArray(item.item)) {
      let values = item.item
      const val = values.map((nestedItem) => nestedItem.title).join(', ');
      return {
        title: getDisplayName(item.title),
        item: val
      }
    }
    else if (item.title === 'Custodians' && Array.isArray(item.item)) {
      let values = item.item.map((nestedItem) => {
        if (companyType === 'buyer') { // Do not display dollar val if buyer
          return nestedItem.split(':')[0];
        }
        else if (!/\d/.test(nestedItem)) {
          // If no $ amount listed, strip ":" from the string
          return nestedItem.replace(/:/g, '');
        }
        return nestedItem;
      });
      return {
        title: getDisplayName(item.title),
        item: values
      };
    }
    else if (item.title == 'aumTotal' || item.title == 'AumTotal') { //Add $
      return {
        title: getDisplayName(item.title),
        item: /^[\$\[\s]/.test(item.item.toLocaleString()) ? item.item : `$${item.item.toLocaleString()}`
      };
    }
    return {
      ...item,
      title: getDisplayName(item.title)
    }
  })

  return (
    <div>
      <h5 className="u-self-start u-pb-6">{title}</h5>
      <div className="u-flex u-flex-col u-gap-2">
        <div className="u-flex u-flex-row u-gap-2">
          {liItems.slice(0, 2).map((item, index) => (
            item.item ? (
              <div
                key={index}
                className="u-flex-1 p-2 box-border"
              >
                <ModuleCard.Body
                  useNewStyling={true}
                  innerCardPadding={['u-px-8', 'u-py-6']}
                  className="u-h-full"
                >
                  <ModuleCard.InfoHeader info={info} trackingName={trackingName} />
                  <p className="lens-overline-text u-mt-0">{item.title}</p>
                  <p className="u-mt-0 u-pt-2">{item.item}</p>
                </ModuleCard.Body>
              </div>
            ) : null // Skip rendering if item.item doesn't exist
          ))}
        </div>
        {liItems.slice(2).map((item, index) => (
          item.item ? (
            <div
              key={index + 2}
              className="p-2 box-border"
            >
              <ModuleCard.Body
                useNewStyling={true}
                innerCardPadding={['u-px-8', 'u-py-6']}
              >
                <ModuleCard.InfoHeader info={info} trackingName={trackingName} />
                <p className="lens-overline-text u-mt-0">{item.title}</p>
                {Array.isArray(item.item) ? (
                  item.item.map((nestedItem, nestedIndex) => (
                    <p key={nestedIndex} className="u-mt-0 u-pt-2">
                      {nestedItem}
                    </p>
                  ))
                ) : (
                  <p className="u-mt-0 u-pt-2">{item.item}</p>
                )}
              </ModuleCard.Body>
            </div>
          ) : null
        ))}
      </div>
    </div>
  )
}

export default CompanyDetails
