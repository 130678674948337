import React from 'react'

import {
  IBasicMetricData,
  IKeyRatiosAndMetricsData
} from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/alaris-utils'
import { ModuleCard } from 'components/layout'



export interface IKeyRatiosAndMetricsProps {
  data: IKeyRatiosAndMetricsData
}

export const KeyRatiosAndMetrics: React.FC<IKeyRatiosAndMetricsProps> = ({
  data: {
    assetsUnderManagement,
    info,
    otherRevenueMetrics,
    title,
    topRevenueMetrics
  }
}) => {
  if (
    (!assetsUnderManagement || assetsUnderManagement.length === 0) &&
    (!otherRevenueMetrics || otherRevenueMetrics.length === 0) &&
    (!topRevenueMetrics || topRevenueMetrics.length === 0)
  )
    return <></>

  const formattedTopRevenueMetrics = topRevenueMetrics.map(
    ({ title, value }) => {
      let formattedTitle
      switch (title) {
        case 'revPerEE':
          formattedTitle = 'Rev Per EE'
          break
        case 'ebitdaMargin':
          formattedTitle = 'EBITDA Margin'
          break
        case 'threeYearAVGAUMGrowth':
          formattedTitle = '3-Year Average AUM Growth'
          break
        default:
          formattedTitle = capitalizeAndSplit(title)
      }

      // find and replace 'Avg' with 'Average'
      formattedTitle = formattedTitle.replace('Avg', 'Average')

      return {
        title: formattedTitle,
        value
      } as IBasicMetricData
    }
  )
  const topOtherRevenueMetrics = otherRevenueMetrics.slice(0, 3)
  const topAssetsUnderManagement = assetsUnderManagement.slice(0, 3)

  return (
    <div className='u-flex u-flex-col u-gap-6 u-w-full'>
      <h5>Assets & Revenue Metrics</h5>
      <div className='u-flex-1'>
        {/* Revenue Data */}
        {formattedTopRevenueMetrics && formattedTopRevenueMetrics.length > 0 && (
          <ModuleCard.Body type={'metric'} metricContent={formattedTopRevenueMetrics} interiorHeading='Revenue' className='u-aspect-[5/4]' />
        )}
      </div>

      <div className='u-flex u-flex-1 u-flex-row u-gap-4'>
        {/* Assets Under Management Data */}
        <div className='u-flex-1 u-text-left'>
          {
            (topAssetsUnderManagement.length > 0) && (
              <ModuleCard.Body interiorHeading='Assets Under Management' type={'metric-stacked'} metricContent={topAssetsUnderManagement} />
            )
          }
        </div>

        {/* Revenue Data */}
        <div className='u-flex-1 u-text-left'>
          {
            (topOtherRevenueMetrics.length > 0) && (
              <ModuleCard.Body interiorHeading='Revenue' type={'metric-stacked'} metricContent={topOtherRevenueMetrics} />
            )
          }
        </div>
      </div>
    </div>
  )
}

export default KeyRatiosAndMetrics
