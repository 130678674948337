/** Reset Message Modal
 *  Displayed after user successfully ressets password
 */
import Link from 'next/link'
import type { FC } from 'react'

import { StyledButton } from 'components'

interface IPasswordResetMessageProps {
  callBack: () => void
  email: string
}

export const PasswordResetMessage: FC<IPasswordResetMessageProps> = ({
  callBack,
  email
}) => {
  return (
    <>
      <h2 className='o-h2'>We&apos;ve sent you an email</h2>
      <p className='o-body-1'>Click on the link we sent to</p>
      <p className='o-body-1 u-mt-0 u-font-bold'>{email}</p>
      <p className='o-body-1'>Didn&apos;t receive the email</p>
      <StyledButton
        type='button'
        themeStyle='ghost'
        theme='secondary'
        className='u-mt-0 u-pt-2'
        onClick={callBack}
      >
        Resend Email
      </StyledButton>
      <Link href='/login'>Back to login</Link>
    </>
  )
}

export default PasswordResetMessage
