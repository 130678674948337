import React from 'react'

import { IClientProfileModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video } from 'components'

interface IClientProfilesModuleProps {
    data: IClientProfileModuleData
}

export const ClientProfilesModule: React.FC<
    IClientProfilesModuleProps
> = ({ data: { info, items, title } }) => {
    if (!items || items.length === 0) return <></>
    title = title || 'Client Profile'
    return (
        <>
            < div>
                <h5 className="u-self-start u-pb-6">{title}</h5>
                {items.map((item, index) => {
                    return (
                        <React.Fragment key={`ClientProfile_${index}`}>
                            <ModuleCard.Body
                                type='description-video'
                                videoContent={item}
                                key={`ClientProfile_${index}`}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}

export default ClientProfilesModule
