import React from 'react'

import { IAdvisorValuePropositionModuleData } from '@unionco/alaris-app-types'
import { ModuleCard } from 'components'
import BulletIcon from 'components/svg/icons/bullet/Bullet'

interface IAdvisorValuePropositionModuleProps {
    data: IAdvisorValuePropositionModuleData
}

export const AdvisorValuePropositionModule: React.FC<
    IAdvisorValuePropositionModuleProps
> = ({ data: { info, items, title } }) => {
    if (!items || items.length === 0) return <></>
    title = title || 'Advisor Value Proposition'
    return (
        <div className='u-pb-11'>
            <h5 className="u-self-start u-pb-6">{title}</h5>
            <div className="u-flex u-flex-col u-gap-2 u-text-left">
                {items.map((item, index) => (
                    <div key={index}>
                        {item.video ? (
                            <ModuleCard.Body
                                type="description-video"
                                videoContent={item}
                                key={`AdvisorProposition_${index}`}
                            />
                        ) : (
                            <ModuleCard.Body
                                useNewStyling={true}
                                innerCardPadding={['u-px-8', 'u-py-6']}
                                className="u-h-full"
                            >
                                <p className="lens-overline-text u-mt-0">{item.title}</p>
                                <div className='u-flex u-flex-row u-items-baseline u-gap-4'>
                                    <BulletIcon />
                                    <p className="u-mt-0 u-pt-2">{item.value}</p>
                                </div>
                            </ModuleCard.Body>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AdvisorValuePropositionModule
