import { IRiskFactorsData } from '@unionco/alaris-app-types'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard, TitledList } from 'components'

interface IRiskFactorsProps {
  data: IRiskFactorsData
}

export const RiskFactors: React.FC<IRiskFactorsProps> = ({ data }) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  const trackingName = 'risk factors module'

  let { info, title } = data
  title = title || 'Key Considerations'
  if (!data.items || data.items.length === 0) return <></>
  return (
    <div>
      <h5 className="u-self-start u-pb-6">{title}</h5>
      <div className="u-flex u-flex-col u-gap-2">
        <ModuleCard.Body useNewStyling={true} innerCardPadding={['u-px-8', 'u-py-4']}>
          {data.items.map((item, index) => (
            <div
              key={index}
              className="u-flex-1 p-2 box-border u-text-left u-mb-6"
            >
              <p className='lens-overline-text u-mt-0'>{item.title}</p>
              <p className='u-mt-0 u-pt-2'>{item.item}</p>

            </div>
          ))}
        </ModuleCard.Body>
      </div>
    </div>
  )
}

export default RiskFactors
