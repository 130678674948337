import { TBgTokenClass } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

export interface IDotLabelProps {
  dotColor: TBgTokenClass
  label: string
}

export type TDotLabels = IDotLabelProps[]

export const DotLabel: React.FC<IDotLabelProps> = ({ dotColor, label }) => {
  return (
    <div className='c-cluster u-flex-wrap u-text-1 [--cluster-gutter:.75rem]'>
      <div className='u-flex u-flex-row u-pr-4'>
        <div
          className={cx(
            'u-w-2 u-h-2 u-aspect-square u-rounded-full u-self-center',
            dotColor
          )}
        />
        <div className='u-flex-wrap'>
          <p className='lens-paragraph-small u-text-left u-whitespace-break-spaces u-pl-2'>{label}</p>
        </div>
      </div>

    </div>
  )
}

export default DotLabel
