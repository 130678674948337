import { ITitledVideoData } from '@unionco/alaris-app-types'

import { ModuleCard, Video } from 'components'

interface ICompanyDetailsProps {
  data: ITitledVideoData
}

export const TitledVideo: React.FC<ICompanyDetailsProps> = ({
  data: { video }
}) => {
  if (!video) return <></>
  return (
    <div>
      <h5 className="u-self-start u-pb-6">{video.title}</h5>
      <ModuleCard.Body className='print:u-hidden' type='video'>
        {video && <Video data={video} videoOnly={true} />}
      </ModuleCard.Body>
    </div>
  )
}

export default TitledVideo
