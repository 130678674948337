/** Video Component
 *  Displays a video stacked on top of a
 *  title and runtime
 *
 *  [----{video}----]
 *  {title} {runtime}
 *
 *  Possible plugin
 *  https://www.npmjs.com/package/next-videos
 *
 */
import { useContext, useEffect, useRef } from 'react'

import type { IVideoData } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'
import { isAdminTracking, videoStartTracking } from 'utils/tracking'

import { TUserContext, UserContext } from 'context'

import { useVimeo } from 'hooks/useVimeo'

import VideoRunTime from './VideoRuntime'

interface IVideoProps {
  className?: string
  data?: IVideoData
  videoOnly?: boolean
}

export const Video: React.FC<IVideoProps> = ({
  className,
  data = { src: '', title: '' },
  videoOnly = false
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const vimeoRef = useRef<HTMLIFrameElement>(null)
  const { playing, runTime } = useVimeo(vimeoRef)
  const videoClasses = videoOnly && className ? className : ''

  useEffect(() => {
    /**
     * When playing changes rerun this useEffect
     */
    if (playing) {
      videoStartTracking({
        video_title: title,
        is_admin_key: isAdminTracking(userType)
      })
    }
  }, [playing, data?.title, userType])

  if (!data || !data.src || data.src === '') return <></>

  const { src, title } = data

  let vidCanEmbed = false
  let correctSrc = src
  if (src.includes('vimeo')) {
    if (src.includes('player.vimeo')) {
      if (correctSrc.charAt(correctSrc.length - 1) != '.') {
        vidCanEmbed = true
      }
    } else {
      let regexp = new RegExp(
        /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
      )
      let vidId = regexp.exec(src)?.at(5)
      if (
        vidId != null &&
        vidId.match(/^[0-9a-z]+$/) &&
        vidId.charAt(0) != '.'
      ) {
        correctSrc = 'https://player.vimeo.com/video/' + vidId
        vidCanEmbed = true
      }
    }
  } else if (src.includes('youtube')) {
    if (src.includes('embed')) {
      vidCanEmbed = true
    } else {
      let regexp = new RegExp(
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      )
      let vidId = regexp.exec(src)?.at(7)
      if (vidId != null) {
        correctSrc = 'https://www.youtube.com/embed/' + vidId
        vidCanEmbed = true
      }
    }
  }

  const video = (
    <div
      className={cx(
        'u-overflow-hidden u-rounded-2xl print:u-hidden',
        videoClasses
      )}
      ref={vimeoRef}
    >
      <iframe
        className={cx(`u-aspect-video u-w-full`, videoClasses)}
        src={correctSrc}
        allow='fullscreen;'
      />
    </div>
  )
  const videoError = (
    <div className='u-overflow-hidden u-rounded u-bg-slate-50'>
      <p className='o-body-1 u-m-8 u-self-auto'>
        Error: Video URL is invalid. Please notify an Alaris team member that
        this link is broken, and we will resolve it promptly
      </p>
    </div>
  )

  if (videoOnly || (!runTime && !title)) return vidCanEmbed ? video : videoError

  return (
    <div className={cx('c-flow print:u-hidden', className)}>
      {vidCanEmbed ? video : videoError}
      <div className='c-dep-repel | u-flow-space--300 u-w-full u-items-center'>
        {title && <p className='o-body-1 u-font-bold u-text-black'>{title}</p>}
        {runTime != 0 ? runTime && <VideoRunTime runTime={runTime} /> : ''}
      </div>
    </div>
  )
}

export default Video
