import { useContext } from 'react'

import { IDownloadItemData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { contentDownloadTracking } from 'utils/tracking/contentDownload'

import { TUserContext, UserContext } from 'context'

import { ProfileTemplateContext } from 'templates/profile/context'

import { DownloadIcon, FileIcon } from 'components/svg'

export const DownloadListItem: React.FC<IDownloadItemData> = ({
  extension,
  href,
  title,
  useNewStyling
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { companyName } = useContext(ProfileTemplateContext)

  function trackingOnClick() {
    contentDownloadTracking({
      element_name: title,
      company_name: companyName,
      element_id: href,
      is_admin_key: isAdminTracking(userType)
    })
  }
  if (!href || href === '#') return <></>
  let content;
  if (useNewStyling) {
    content = (
      <a
        href={href}
        className={'body-1 c-dep-repel u-block u-w-full u-h-full'}
        download
        onClick={trackingOnClick}
        target='_blank'
        rel='noopener noreferrer'
      >
        <div className='u-flex u-items-center  u-py-4 u-pl-4 u-pr-6'>
          <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
            <FileIcon className='u-ml-3.5 u-w-3.5' />
          </div>
          <span className='lens-small-paragraph-text u-pl-4 u-font-semibold'>{title}</span>
          <div className='u-flex u-items-center u-ml-auto '>
            <DownloadIcon className='u-ml-3.5 u-text-secondary-800 u-w-6 u-h-6' />
          </div>
        </div>
      </a>
    )
  } else {
    content = (
      <a
        href={href}
        className={'o-body-1 c-dep-repel u-py-3'}
        download
        onClick={trackingOnClick}
        target='_blank'
        rel='noopener noreferrer'
      >
        <span className='o-subtitle-2 u-text-secondary-800'>{title}</span>
        <div className='u-flex u-items-center'>
          <span className='u-text-secondary-900'>{extension}</span>
          <DownloadIcon className='u-ml-3.5 u-w-3.5 u-text-primary-500' />
        </div>
      </a>
    )
  }
  return content
}

export default DownloadListItem
