/** Dashboard Layout Main
 *  Goes inside of dashboard body below header
 *  Holds main content for dashboard layouts
 */
import type { ILayoutComponentProps } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

export const DashboardLayoutMain: React.FC<ILayoutComponentProps> = ({
  children,
  className,
  useNewStyling = false
}) => {
  return (
    <div
      className={cx(
        useNewStyling ? 'u-flex-grow u-pb-700' : 'u-flex-grow u-bg-gradient-blue-purple u-pb-700',
        className
      )}
    >
      <div className='c-wrapper c-flow | u-relative u-text-justify'>{children}</div>
    </div>
  )
}

export default DashboardLayoutMain
