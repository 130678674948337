import { Card } from '@unionco/components'
import tw from 'twin.macro'

import { IBasicMetricData } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

interface IMetricCardClassNames {
  root: string
  title: string
  value: string
}

interface IMetricCardProps {
  className?: string
  classNames?: Partial<IMetricCardClassNames>
  data: IBasicMetricData | IBasicMetricData[]
  useNewStyling?: boolean
  stackedLayout?: boolean
}

export const MetricCard: React.FC<IMetricCardProps> = ({
  className,
  classNames = {},
  data,
  useNewStyling = false,
  stackedLayout = false
}) => {
  let title;
  let value;

  if (!Array.isArray(data)) {
    title = data.title
    value = data.value
  }

  if (useNewStyling) {
    if (stackedLayout && Array.isArray(data)) {
      return (
        <>
          {data.map((metric: IBasicMetricData, index) => (
            <div key={`metric-${metric.title}-${index}`}>
              <div className='u-flex u-flex-row u-justify-between u-gap-2 u-items-center u-h-full' >
                <div>
                  <p className='u-font-semibold u-text-newprimary-pureblack'>
                    {metric.title}
                  </p>
                  <p className='lens-small-paragraph-text'>
                    {metric.subText}
                  </p>
                </div>
                <h5 className='u-text-newprimary-700'>
                  {metric.value}
                </h5>
              </div>
              {index < data.length - 1 && <hr />}
            </div>
          ))}
        </>
      )
    }
    return (
      <div className='u-flex u-flex-1 u-flex-col u-justify-between u-py-6 u-gap-2 u-items-center u-self-stretch'>
        <p className='lens-small-paragraph-text u-text-newsecondary-500 u-text-center'>
          {title}
        </p>
        <h5 className='u-text-newprimary-700'>
          {value}
        </h5>
      </div>
    )
  }
  else return (
    <Card
      bgColorClass={tw`u-bg-primary-300`}
      className={cx(
        'c-flow | u-flow-space--200 | u-grow u-px-500 u-py-500 u-text-center print:u-p-300 print:break-after-avoid-page',
        className,
        classNames.root
      )}
    >
      <p className={cx('o-body-1 o-subtitle-2 u-text-secondary-800', classNames.title)}>
        {title}
      </p>
      <div className="u-mt-auto">
        <p
          className={cx('o-h3 u-font-bold u-text-primary-500', classNames.value)}
        >
          {value}
        </p>
      </div>
    </Card>
  )
}


export default MetricCard
