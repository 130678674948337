import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const LocationIcon: React.FC<TSVGProps> = ({ ...props }) => {
    return (
        <IconSVG
            viewBoxWidth={24}
            viewBoxHeight={25}
            xmlns='http://www.w3.org/2000/svg'
            {...props}
            ref={undefined}
        >
            <path
                d="M12 2.5C7.6 2.5 4 6.1 4 10.5C4 15.9 11 22 11.3 22.3C11.5 22.4 11.8 22.5 12 22.5C12.2 22.5 12.5 22.4 12.7 22.3C13 22 20 15.9 20 10.5C20 6.1 16.4 2.5 12 2.5ZM12 20.2C9.9 18.2 6 13.9 6 10.5C6 7.2 8.7 4.5 12 4.5C15.3 4.5 18 7.2 18 10.5C18 13.8 14.1 18.2 12 20.2ZM12 6.5C9.8 6.5 8 8.3 8 10.5C8 12.7 9.8 14.5 12 14.5C14.2 14.5 16 12.7 16 10.5C16 8.3 14.2 6.5 12 6.5ZM12 12.5C10.9 12.5 10 11.6 10 10.5C10 9.4 10.9 8.5 12 8.5C13.1 8.5 14 9.4 14 10.5C14 11.6 13.1 12.5 12 12.5Z"
                fill="#647381"
            />
        </IconSVG>
    )
}

export default LocationIcon
