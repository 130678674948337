import React from 'react'

import { ISeekingPartnershipModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video } from 'components'

interface ISeekingPartnershipModuleProps {
    data: ISeekingPartnershipModuleData
}

export const SeekingPartnershipModule: React.FC<
    ISeekingPartnershipModuleProps
> = ({ data: { info, items, title } }) => {
    if (!items || items.length === 0) return <></>
    title = title || 'Partnership Preferences'
    return (
        <>
            <div className="u-text-left">
                <h5 className="u-self-start u-pb-6 u-text-left">{title}</h5>
                {items.map((item, index) => {
                    item.title = 'What they are seeking in a partnership'
                    return (
                        <React.Fragment key={`SeekingPartnership_${index}`}>
                            <ModuleCard.Body
                                innerCardPadding={['u-px-8', 'u-py-6']}
                                type='description-video'
                                videoContent={item}
                                key={`SeekingPartnership_${index}`}
                            />
                        </React.Fragment>
                    )
                })}
            </div>
        </>
    )
}

export default SeekingPartnershipModule
